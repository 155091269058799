import {graphql} from 'gatsby';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import SbEditable from 'storyblok-react';

import Content404 from '@/components/Content404/Content404';
import Layout from '@/components/Layout/Layout';

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en-uk/';
  }

  const lang = navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'en-uk/';

  switch (lang) {
    case 'fr':
      return 'default';
    default:
      return 'en-uk/';
  }
};

const Components = {
  content404: Content404,
};

const NotFoundPage = ({data}) => {
  let story = data.storyblokEntry;
  story.content = JSON.parse(story.content);
  const filteredBlocks = story.content.blocks;

  useEffect(() => {
    const urlLang = getRedirectLanguage();
    if (urlLang !== 'default' && urlLang !== data.storyblokEntry.lang + '/') {
      navigate(`/${urlLang}404`);
    }
  }, []);

  // const contents404 = data.allStoryblokEntry.edges;
  // let filteredContents404 = {};
  // contents404.forEach((story) => {
  //   filteredContents404[story.node.lang] = JSON.parse(story.node.content);
  // });

  return (
    <>
      <Helmet>
        <title>{'404 - Alma - Le paiement en plusieurs fois garanti. Simple.'}</title>
      </Helmet>
      <Layout>
        {/* {filteredContents404['en-uk'].blocks.map((block) => { */}
        {filteredBlocks.map((block) => {
          if (typeof Components[block.component] !== 'undefined') {
            const Component = Components[block.component];
            return (
              <SbEditable key={block._uid} content={block}>
                <Component {...block} />
              </SbEditable>
            );
          }
        })}
      </Layout>
    </>
  );
};

export const query = graphql`
  query page404($lang: String) {
    storyblokEntry(slug: {eq: "content404"}, lang: {eq: $lang}) {
      content
      lang
    }
  }
`;

// export const query = graphql`
//   query page404 {
//     allStoryblokEntry(filter: {slug: {eq: "content404"}}) {
//       edges {
//         node {
//           content
//           lang
//         }
//       }
//     }
//     storyblokEntry(slug: {eq: "content404"}) {
//       content
//     }
//   }
// `;

export default NotFoundPage;

NotFoundPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
